import * as React from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { Global } from '@emotion/core'
import { node } from 'prop-types'
import {useEffect, useState} from "react";
import GoogleFontLoader from 'react-google-font-loader';

function ThemeWrapper({ children }) {
	const { styles, client } = useSelector(state => state.user)
	const [fonts, setFonts] = useState({});
	const theme = JSON.parse(localStorage.getItem('client-theme'))

	const baseFont = theme?.baseFont || client?.scheme?.baseFont;
	const headerFont = theme?.headerFont || client?.scheme?.headerFont;

	useEffect(() => {
		if(baseFont || headerFont) {
			const schemeFonts = [];
			if(baseFont) {
				schemeFonts.push({
					font: baseFont,
					weights: [300, 400, 500, 700]
				})
			}
			if(headerFont) {
				schemeFonts.push({
					font: headerFont,
					weights: [300, 400, 500, 700]
				})
			}
			setFonts(schemeFonts);
		}
	}, [client?.scheme])

	return (
		<>
			{!R.isEmpty(fonts) &&
			<GoogleFontLoader
				fonts={fonts}
			/>}

			{styles && <Global styles={styles} />}
			{children}
		</>
	)
}

ThemeWrapper.propTypes = {
	children: node.isRequired
}

export default ThemeWrapper
