import * as React from 'react'
import { useSelector } from "react-redux"
import Link from '@/utils/Link'
import { string, node, bool } from 'prop-types'
import classNames from 'classnames'
import useCCVOnly from "@/hooks/useCCVOnly";
import styles from './DesktopNavigation.module.css'

function MenuLink({ children, href, ...rest }) {
	return (
		<li className="mx-lg">
			<Link
				partiallyActive
				to={href}
				activeClassName={classNames('text-accent', styles.active)}
				className={classNames(
					'relative flex px-xs items-stretch font-h-light h-full text-primary hover:text-accent before:bg-primary',
					styles.link
				)}
				{...rest}
			>
				<span className="self-center">{children}</span>
			</Link>
		</li>
	)
}

MenuLink.propTypes = {
	children: node.isRequired,
	href: string.isRequired
}

function DesktopNavigation({ hidden = true }) {
	const { cmsData, userType, employmentStatus } = useSelector(({ user }) => user)
	const showBenefits = !useCCVOnly();
	const { faqs } = cmsData
	let showEmployerDashboard = false;
	if(userType === 'employer') {
		if(employmentStatus !== 'left' && employmentStatus !== 'inactive') {
			showEmployerDashboard = showBenefits;
		}
	}

	return (
		<ul
			data-testid="desktop-nav"
			className="hidden h-full overflow-hidden md:flex md:ml-xl lg:ml-2xl"
			hidden={hidden}
		>
			<MenuLink data-testid="Home" href="/app/home/">
				Home
			</MenuLink>
			{showBenefits && (
				<MenuLink data-testid="My benefits" href="/app/my-benefits/">
					My benefits
				</MenuLink>
			)}
			{faqs && (
				<MenuLink data-testid="FAQs" href="/app/faqs/">
					FAQs
				</MenuLink>
			)}
			{showEmployerDashboard && (
				<MenuLink data-testid="Dashboard" href="/app/dashboard/">
					Dashboard
				</MenuLink>
			)}
		</ul>
	)
}

DesktopNavigation.propTypes = {
	hidden: bool
}

export default DesktopNavigation
