import * as React from 'react'
import { string, node, bool } from 'prop-types'
import Text from './Text'

function InputError({ id, children, show = false, ...rest }) {
	if (!show) return null

	return (
		<Text
			inline
			data-testid={`${id}-error`}
			className="relative w-full text-sm text-white px-sm bottom-sm pt-md pb-sm rounded-b-10 bg-s-error-bg-50"
			{...rest}
		>
			{children}
		</Text>
	)
}

InputError.propTypes = {
	show: bool,
	id: string,
	children: node
}

export default InputError
