import * as React from 'react'
import { string, node, bool, oneOfType } from 'prop-types'
import classNames from 'classnames'
import Node from '@/utils/Node'
import InputError from '@/utils/InputError'

const Input = React.forwardRef(
	(
		{
			id,
			name,
			children,
			className,
			wrapperClassName,
			error,
			as = 'input',
			showErrors = true,
			touched,
			...rest
		},
		ref
	) => {
		const stringError = typeof error !== 'boolean'

		return (
			<div
				className={classNames(
					{ 'relative z-10': error && showErrors },
					'rounded-10',
					wrapperClassName
				)}
			>
				<Node
					as={as}
					id={id}
					ref={ref}
					name={name}
					data-testid={`${name}-input`}
					className={classNames(
						'relative z-10 block w-full text-base text-grey apperance-none p-sm rounded-10 border-2 outline-none leading-none',
						className,
						{
							'border-s-error-50': error,
							'border-b-0': error && showErrors && stringError,
							'border-primary-60 focus:border-primary-80': !error
						},
						{ 'h-40 md:h-48': as !== 'textarea' }
					)}
					{...rest}
				>
					{children}
				</Node>
				{showErrors && stringError && (
					<InputError data-testid={`${name}-error`} show={!!error} id={id}>
						{error}
					</InputError>
				)}
			</div>
		)
	}
)

Input.propTypes = {
	name: string.isRequired,
	id: string,
	children: node,
	className: string,
	as: string,
	error: oneOfType([bool, string, node]),
	showErrors: bool,
	touched: bool,
	wrapperClassName: string
}

export default Input
