import * as React from 'react'
import { bool } from 'prop-types'
import Link from '@/utils/Link'
import Text from '@/utils/Text'

function Footer({ contactUsEnabled }) {
	const links = [
		{
			title: 'Privacy',
			slug: '/privacy-statement',
			id: 1
		},
		{
			title: 'Terms',
			slug: '/terms-and-conditions',
			id: 2
		}
	]





	const year = new Date().getFullYear()

	return (
		<footer className="z-10 flex-col md:flex-row text-center bg-white shadow-footer md:text-left flex md:justify-between md:h-footer items-center">
			<div className="flex items-center">
				<img
					alt=""
					src="/logos/beam-svg-v4.svg"
					className="hidden md:block w-140 mr-md ml-sm"
				/>
				<img
					alt=""
					src="/logos/beam-svg-v4.svg"
					className="md:hidden mt-xl w-140"
				/>
				<Text as="h2" inline className="hidden md:block text-base mb-xl text-grey-100 md:mb-0">
					&copy; {year} Cloud8 - All rights reserved
				</Text>
			</div>

			<ul className="flex justify-center mr-lg mt-xl md:mt-0 ">
				{links.map((link, i) => (
					<div key={link.id} className='flex md:block'>
						<li className="stack md:ml-xl">
							<Link activeClassName="text-accent underline" to={link.slug}>
								{link.title}
							</Link>
						</li>
						{(links.length !== i + 1) && (
							<li className="md:hidden ml-sm mr-sm">
								&bull;
							</li>
						)}
					</div>
				))}
				{contactUsEnabled && (
					<>
						<li className="md:hidden ml-sm mr-sm">
							&bull;
						</li>
						<li className="stack mb-xl md:mb-0 md:ml-xl">
							<Link activeClassName="text-accent underline" to="/contact-us/">
								Contact
							</Link>
						</li>
					</>
				)}
			</ul>

			<Text as="h2" inline className="md:hidden text-base mb-xl text-grey-100 md:mb-0 mt-md">
				&copy; {year} Cloud8 - All rights reserved
			</Text>

		</footer>
	)
}

Footer.propTypes = {
	contactUsEnabled: bool
}

export default Footer
