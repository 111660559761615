import * as React from 'react'
import Link from '@/utils/Link'
import {useSelector} from "react-redux"
import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import BurgerButton from '@/components/buttons/BurgerButton/'
import useLogout from '@/hooks/useLogout'
import styles from './MobileNavigation.module.css'
import useCCVOnly from "@/hooks/useCCVOnly";

const getNavigationLinks = (showFAQPage, showEmployerDashboard, showBenefits) =>
{
	return [
		{
			slug: '/app/home/',
			title: 'Home'
		},
		showBenefits && {
			slug: '/app/my-benefits/',
			title: 'My benefits'
		},
		showFAQPage && {
			slug: '/app/faqs/',
			title: 'FAQs'
		},
		showEmployerDashboard && {
			slug: '/app/dashboard/',
			title: 'Dashboard'
		},
		{
			slug: '/app/home/my-profile/',
			title: 'Profile Settings'
		}
	].filter(Boolean)
}


function MobileNavigation() {
	const [isOpen, setOpen] = React.useState(false)
	const logout = useLogout()
	const onClick = () => {
		setOpen(!isOpen)
	}

	const { cmsData, userType, employmentStatus } = useSelector(({ user }) => user)
	const showBenefits = !useCCVOnly();
	const { faqs } = cmsData
	let showEmployerDashboard = false;
	if(userType === 'employer') {
		if(employmentStatus !== 'left' && employmentStatus !== 'inactive') {
			showEmployerDashboard = showBenefits;
		}
	}
	
	const links = getNavigationLinks(faqs, showEmployerDashboard, showBenefits)

	return (
		<>
			<BurgerButton className="md:hidden" onClick={onClick} isOpen={isOpen} />

			<AnimatePresence>
				{isOpen && (
					<motion.div
						data-testid="mobile-nav"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						className={classNames(
							'bg-accent w-full h-screen flex flex-col items-center justify-center fixed inset-0 z-40',
							styles.wrapper
						)}
						key="mobile-nav"
					>
						<ul>
							{links.map(({ slug, title }) => (
								<li key={title} className="text-center mb-xl">
									<Link
										role="menuitem"
										to={slug}
										className={classNames('text-white text-md')}
										onClick={onClick}
									>
										{title}
									</Link>
								</li>
							))}
							<li className="text-center mb-xl">
								<Link
									role="menuitem"
									to="#0"
									className={classNames('text-white text-md')}
									onClick={logout}
									data-testid="logout"
								>
									Logout
								</Link>
							</li>
						</ul>
					</motion.div>
				)}
			</AnimatePresence>
		</>
	)
}

export default MobileNavigation
