/* eslint-disable camelcase */
import * as React from 'react'
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11'
import { useAsync } from 'react-async-hook'
import { useSelector } from 'react-redux'
import { shape, arrayOf, string, oneOf, func } from 'prop-types'
import classNames from 'classnames'
import Loading from '@/components/screens/Loading'
import Layout from '@/container/Layout'
import Breadcrumb from '@/components/global/Breadcrumb'
import Select from '@/components/formFields/Select'
import TextArea from '@/components/formFields/TextArea'
import PrimaryButton from '@/components/buttons/Button'
import Box from '@/utils/Box'
import Notification from '@/components/user/Notification'
// import Attachments from '@/components/formFields/Attachments'
import fetchApi from '@/utils/fetchApi'
import RichText from '@/utils/RichText'
import Text from '@/utils/Text'
import LabelText from '@/utils/LabelText'
import * as restAPI from '@/utils/endpoints'
import useFocusOnFirstInput from '@/hooks/useFocusOnFirstInput'
import useFormState from '@/hooks/useFormState'
import withAuth from '@/utils/withAuth'
import * as m from '@/utils/messages'
import { unconfirmedSupportNumbers } from '@/utils/unconfirmedSupportNumbers'

const fetchContact = async () => fetchApi(restAPI.BEAM_CONTACT)

const FORM = 'FORM'
const SUCCESS = 'SUCCESS'
const ERROR = 'ERROR'

function Form({ enquiryOptions, setView }) {
	const user = useSelector(state => state.user)
	const {
		register,
		unregister,
		handleSubmit,
		errors,
		formState,
		setValue,
		setError
	} = useForm({
		mode: 'onBlur'
	})
	const formRef = React.useRef()

	const { benefitBrandName } = user

	// use the form ref to find the first input to focus
	useFocusOnFirstInput(formRef)

	// update the loading state
	// based on whether the form is
	// currently submitting or not.
	useFormState(formState.isSubmitting)

	const hasFieldErrors = !!Object.keys(errors).length
	// only show the submit errors if a submission has been
	// attempted, and the submission set the loginError
	// and, only if the form is not currently being submitted
	const showFormErrors =
		formState.submitCount > 0 && !formState.isSubmitting && hasFieldErrors

	// submit the form
	// we only need to dispatch the submit action
	// we will handle the response via the user store
	// if successful, loggedIn will be set to true
	// triggering the next hook, if it fails loginError
	// will be set, triggering the second hook
	const onSubmit = async data => {
		const { client, email, firstName, surname } = user

		const response = await fetchApi(restAPI.CONTACT, {
			body: JSON.stringify({
				...data,
				email,
				firstName,
				surname,
				company: client.clientName
			}),
			method: 'POST'
		})

		if (response.status === 'success') {
			setView(SUCCESS)
			return
		}

		if (response.status === 'error') {
			const { _person_enq, _message } = response.fields

			if (_person_enq) {
				setError(
					'subject',
					'missing',
					'Please provide a subject for your message'
				)
			}

			if (_message) {
				setError('comments', 'missing', 'This field is required')
			}
		}
	}

	React.useEffect(() => {
		register(
			{
				name: 'subject'
			},
			{
				required: {
					value: true,
					message: 'This field is required'
				}
			}
		)

		return () => {
			unregister('subject')
		}
	}, [register, unregister])

	return (
		<form
			ref={formRef}
			data-testid="contact-form"
			noValidate
			method="post"
			onSubmit={handleSubmit(onSubmit)}
		>
			{showFormErrors && (
				<Notification data-testid="form-errors" level="error" className="mb-lg">
					{m.PLEASE_COMPLETE_FORM(benefitBrandName)}
				</Notification>
			)}

			<label htmlFor="subject" className="block mb-lg md:mb-xl">
				<LabelText>What is the subject of your enquiry?</LabelText>
				<div className="relative z-30 rounded-10">
					<Select
						items={enquiryOptions}
						onChange={selection => {
							setValue('subject', selection.value)
						}}
						error={errors?.subject?.message}
					/>
				</div>
			</label>
			<label htmlFor="comments" className="block mb-lg md:mb-xl">
				<LabelText>Comments</LabelText>
				<TextArea
					data-testid="comments"
					id="comments"
					name="comments"
					rows={5}
					className={classNames({
						'border-primary-60': !errors?.comments?.message
					})}
					error={errors?.comments?.message}
					ref={register({
						required: {
							value: true,
							message: 'This field is required'
						}
					})}
				/>
			</label>

			<PrimaryButton
				as="button"
				type="submit"
				data-testid="submit"
				disabled={formState.isSubmitting}
				className="w-full mb-2xl lg:mb-0 md:w-auto md:px-2xl"
			>
				Submit
			</PrimaryButton>
		</form>
	)
}

Form.propTypes = {
	enquiryOptions: arrayOf(
		shape({
			label: string,
			value: string.isRequired
		}).isRequired
	).isRequired,
	setView: func.isRequired
}

function Feedback({ level, title, text }) {
	return (
		<Notification
			data-testid={level}
			level={level}
			className="text-center mb-lg md:p-lg"
		>
			<Text className="text-md mb-xl">{title}</Text>
			<Text className="text-md">{text}</Text>
		</Notification>
	)
}

Feedback.propTypes = {
	level: oneOf(['success', 'error']).isRequired,
	title: string.isRequired,
	text: string.isRequired
}

function ContactUs() {
	const { supportNumber, supportEmail } = useSelector(state => state.user)
	const fetchDetails = useAsync(fetchContact, [])
	const [view, setView] = React.useState(false)
	const [pageData, setPageData] = React.useState({})

	const SHOW_FORM = view === FORM
	const SHOW_SUCCESS = view === SUCCESS
	const SHOW_ERROR = view === ERROR

	React.useEffect(() =>
	{
		if (!view && fetchDetails.status !== 'loading')
		{
			if (fetchDetails.result?.error)
			{
				setView(ERROR)
				// return
			}
			else if (fetchDetails.status === 'success')
			{
				const { enquiryOptions, pageContent } = fetchDetails.result

				let title, content = null;

				if(pageContent)
				{
					title = pageContent.name
					content = pageContent.content
				}

				setView(FORM)
				setPageData({ title, content, enquiryOptions })
			}
		}
	}, [fetchDetails.status, fetchDetails.result, setView, view])

	return (
		<Layout className="flex-grow pb-lg lg:pb-2xl">
			{fetchDetails.status === 'loading' && <Loading>Loading profile</Loading>}
			{view && (
				<>
					<div className="mb-lg">
						<Breadcrumb
							items={[
								{ href: '/app/home/', text: 'Home' },
								{ href: '/contact-us/', text: 'Contact Us' }
							]}
						/>
					</div>

					<Text as="h2" className="text-mob-2xl md:text-2xl mb-lg">
						Contact us
					</Text>
					<div className="lg:grid lg:grid-cols-12 lg:gap-xl ie:flex ie:justify-between">
						<div className="lg:col-span-6 ie:w-1/2">
							{SHOW_FORM && (
								<Form
									enquiryOptions={pageData.enquiryOptions}
									setView={setView}
								/>
							)}
							{SHOW_SUCCESS && (
								<Feedback
									title="Your message has been sent!"
									text={`We will contact you as soon as possible.`}
									level="success"
								/>
							)}
							{SHOW_ERROR && (
								<Feedback
									title="There was a problem loading this page"
									text={((unconfirmedSupportNumbers.includes(supportNumber) ? 
										`Please try again. If your query is urgent, please <a href='mailto:${supportEmail}\`'>email us</a>` :
										`Please try again. If your query is urgent, please call us on: ${supportNumber}`))}
									level="error"
								/>
							)}
						</div>

						{!SHOW_ERROR && pageData.content && (
							<div className="lg:col-span-5 lg:col-end-13 ie:w-1/3">
								<Box className="bg-white shadow p-lg md:p-xl">
									<div className="text-grey-80">
										<Text
											as="h3"
											className="text-lg mb-lg font-h-light md:auto"
										>
											{pageData.title}
										</Text>
										<RichText
											dangerouslySetInnerHTML={{
												__html: pageData.content
											}}
										/>
									</div>
								</Box>
							</div>
						)}
					</div>
				</>
			)}
		</Layout>
	)
}

export default withAuth(ContactUs)

/* 
<div className="mb-lg md:mb-xl">
	<Attachments
		id="files"
		name="files"
		setFieldValue={setFieldValue}
		value={values.files}
		error={errors.files}
		onChange={handleChange}
		onBlur={handleBlur}
		label="Upload"
	/>
</div> 
*/
